import { Route, Routes } from '@angular/router';
import { AUDIENCE_SLUGS } from '@routes/routes.types';
export const SEGMENTS_SLUGS = {
    table: 'table',
    variant: 'variant',
    channel: 'channel',
    form: 'form',
    landing: 'landing',
} as const;
export const SEGMENTS_ROUTES: Routes = [
    {
        path: SEGMENTS_SLUGS.table,
        title: 'Segments',
        loadComponent: () =>
            import('@features/audience/segments/segments-table/segments-table.component').then(
                m => m.SegmentsTableComponent
            ),
    },
    {
        path: `${SEGMENTS_SLUGS.form}/:id`,
        title: 'Update Segment',
        loadComponent: () =>
            import('@features/audience/segments/segment-form/segment-form.component').then(
                m => m.SegmentFormComponent
            ),
    },
    {
        path: SEGMENTS_SLUGS.form,
        title: 'Create Segment',
        loadComponent: () =>
            import('@features/audience/segments/segment-form/segment-form.component').then(
                m => m.SegmentFormComponent
            ),
    },
    {
        path: SEGMENTS_SLUGS.variant,
        title: 'Choose Variant',

        loadComponent: () =>
            import(
                '@features/audience/segments/segment-variant-choose/segment-variant-choose.component'
            ).then(m => m.SegmentVariantChooseComponent),
    },
    {
        path: SEGMENTS_SLUGS.landing,
        title: 'Segments',
        loadComponent: () =>
            import('@features/audience/segments/segments-landing/segments-landing.component').then(
                m => m.SegmentsLandingComponent
            ),
    },

    { path: '**', redirectTo: SEGMENTS_SLUGS.table },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AUDIENCE_SLUGS.segments,
    },
] as const;
export const SEGMENTS_ROUTE: Route = {
    path: AUDIENCE_SLUGS.segments,
    loadComponent: () =>
        import('@features/audience/segments/segments.component').then(m => m.SegmentsComponent),
    children: SEGMENTS_ROUTES,
};
